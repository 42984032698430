.sessao-sobre-nos {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 2rem 0;
  background-color: #fff;
}

.sobre-nos-1 {
  background-image: url("../../../assets/images/banner-sobre-nos.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  color: #104501;
  padding: 0;
  display: flex;
  padding: 2rem;

  .texto-motivacao {
    font-size: 1.2rem;
    color: #104501;
    margin: 1rem 0;
    font-weight: 700;
  }

  .video-sobre-nos {
    border-radius: 2rem;
  }
}

.sobre-nos-2 {
  background-color: #fdfdfd91;
  .valores {
    padding: 2rem;

    .item-valores {
      box-shadow: 2px 2px 10px 0px #0000001f;
      display: flex;
      align-items: center;
      padding: 2rem;
      margin: 0.8rem 0;
      border-radius: 1rem;
      background-color: #d8ff57;
    }
  }
}

.sobre-nos-3 {
  background-color: #fdfdfd91;
  padding: 2rem;
  display: flex;
  justify-content: center;

  .card-dados {
    padding: 2rem;
    width: 100%;
    min-height: 250px;
    margin: 2rem 2rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    box-shadow: 2px 2px 10px 0px #0000001f;
    padding: 1rem;
  }

  .botao-cadastrar {
    margin-top: 2rem;
    font-size: 1.2rem;
    background-color: #d8ff57;
    color: #104501;

    @media (min-width: 1000px) {
      font-size: 1.5rem;
    }
  }

  .verde {
    color: #fff;
    background-color: #104501;
  }

  .amarelo {
    background-color: #ffd12f;
  }

  .rosa {
    background-color: #ffb3d2;
  }
}
