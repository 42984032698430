.swiper-button-next,
.swiper-button-prev {
  background-color: transparent;

  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #ffd12f;
  font-size: 18px;
}

.secao-site {
  width: 100%;
  max-width: 100%;
  margin: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 2rem 0;
}

.secao-1 {
  background-image: url("../../../assets/images/banner-mobile.jpg");
  color: #fff;
  padding: 0;
  margin: auto;
  min-height: 100vh;

  @media (min-width: 600px) {
    display: flex;
    align-items: center;
    background-image: url("../../../assets/images/banner.jpg");
  }

  .texto-parcele-em-x-vezes {
    font-size: 5rem;
    font-weight: bold;
  }
  .texto-secundario {
    font-weight: bold;
    font-size: 2rem;
    width: 100%;

    @media (min-width: 1000px) {
      width: 70%;
    }
  }
  @media (max-width: 1000px) {
    .texto-parcele-em-x-vezes {
      font-size: 3rem;
    }
    .texto-secundario {
      font-size: 1.6rem;
    }
  }
  @media (max-width: 600px) {
    .texto-parcele-em-x-vezes {
      font-size: 2rem;
    }
    .texto-secundario {
      font-size: 1.4rem;
    }
  }
  .banner {
    max-width: 80%;
    text-align: center;
  }

  .botao-container {
    @media (max-width: 600px) {
      height: 55vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  .botao-simular-principal {
    padding: 12px 40px;
    font-size: 1.2rem;
    color: #d8ff57;
  }
}

.secao-2 {
  background-image: url("../../../assets/images/banner-secao-2-mobile.jpg");
  margin: auto;
  min-height: 100vh;

  @media (min-width: 600px) {
    padding: 2rem;
  }

  .container-texto {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @media (min-width: 600px) {
      margin: 0;
    }

    .texto-secao {
      font-size: 1.5rem;
      color: #d8ff57;

      @media (min-width: 600px) {
        font-size: 2.5rem;
      }

      .texto-destaque {
        color: #ffb3d2;
        font-weight: bold;
      }
    }
  }

  .botao-simular-amarelo {
    background-color: #ffc800;
    color: #004300;
    font-size: 1rem;

    @media (min-width: 600px) {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 1000px) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    background-image: url("../../../assets/images/banner-secao-2.png");
  }
}

.secao-conte-conosco {
  background-image: url("../../../assets/images/banner-secao-conte-conosco.png");
  padding: 2rem 0;

  .titulo {
    color: #104501;
    font-size: 2rem;
    font-weight: bold;
    line-height: 35px;
    margin-top: 1.5rem;

    @media (max-width: 600px) {
      padding: 1rem;
      text-align: center;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    min-height: 310px;
    width: 100%;
    border-radius: 1rem;

    @media (min-width: 1000px) {
      justify-content: space-between;
      margin: 2rem 1rem;
      min-height: 510px;
    }
  }
}

.secao-3 {
  background-image: url("../../../assets/images/banner-secao-3.png");

  @media (min-width: 1000px) {
    padding: 2rem;
  }

  .titulo {
    padding: 2rem 0;
    color: #104501;
    font-size: 2.6rem;
    font-weight: bold;
  }

  .card-beneficios {
    margin-bottom: 1rem;
    width: 100%;
    min-height: 130px;

    .text {
      font-size: 18px;
      padding: 2rem 4rem;
      align-self: center;
      line-height: 1.2;
      color: "#104501";

      .titulo-beneficio {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }

  .botao-simular {
    background-color: #d8ff57;
    color: #004300;
    font-size: 1.5rem;
  }
}

.secao-4 {
  background-image: url("../../../assets/images/banner-secao-4.png");
  display: flex;
  align-items: center;
  min-height: 80vh;

  @media (min-width: 1000px) {
    padding: 2rem;
  }

  .card-estatistica {
    left: 40px;
    position: relative;

    @media (max-width: 600px) {
      left: 20px;
    }

    .card-number {
      align-items: center;
      color: #ffd12f;
      border-radius: 2rem;
      font-weight: bold;
      width: 170px;
      height: 95%;

      @media (max-width: 600px) {
        width: 90px;
      }
    }
  }

  .card-avaliacao {
    margin-bottom: 1rem;
    width: 100%;
    min-height: 130px;
    background-color: #d8ff57;

    .text {
      padding: 2rem 4rem;
      align-self: center;
      line-height: 1.2;
      color: "#104501";

      @media (max-width: 600px) {
        padding: 1rem 2rem;
      }
    }
  }
}

.secao-qualidade-testada {
  color: #2e302e;
  background-color: #f7f7f7;

  .titulo {
    color: #104501;
    font-size: 3rem;
    font-weight: bold;
  }
  .imagens {
    display: flex;
    margin-top: 2rem;
  }
  @media (max-width: 600px) {
    .titulo {
      font-size: 2rem;
    }
  }
  .item-programas {
    .card-programas {
      height: 170px;
      border-radius: 2rem;
      display: flex;
      align-items: center;
    }

    .imagem {
      text-align: center;
      margin: auto;

      img {
        width: 70%;
      }
    }
  }
}

.secao-5 {
  padding: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;

  .logo-reclame-aqui {
    width: 40%;

    @media (max-width: 600px) {
      width: 60%;
    }
  }
}
