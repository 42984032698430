.sessao-site-como-usar {
  width: 100%;
  max-width: 100%;
  margin: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 2rem 0;
}

.sessao-como-usar-1 {
  background-image: url("../../../assets/images/banner-como-usar.png");
  color: #104501;
  padding: 0;
  display: flex;
  align-items: center;
  margin: auto;
  min-height: 100vh;

  .descriao-item {
    padding: 0 1rem;
  }

  .item-como-usar {
    background-color: #d8ff57;
    box-shadow: 0px 2px 4px -1px #00000038, 0px 1px 15px 0px #00000024,
      0px 0px 1px 0px #0000001f;
    padding: 1.5rem 1rem 1rem 1rem;
    min-height: 90px;
    border-radius: 10px;
    height: 100px;
    margin: 1rem 0;

    .subtitulo {
      font-size: 1.2rem;
      font-weight: bold;
      padding-bottom: 0.3rem;
    }

    .titulo-numero {
      text-align: center;
      color: #104501;
      font-size: 4rem;
      font-weight: bold;
      line-height: 50px;
    }
  }

  .container-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .botao-simular {
    font-size: 1.2rem;
    background-color: #ffb3d2;
    color: #104501;

    @media (min-width: 1000px) {
      font-size: 1.5rem;
    }
  }

  .bandeiras-aceitas {
    .card-bandeiras {
      height: 100px;
      display: flex;
      align-items: center;
    }

    .imagem {
      text-align: center;
      margin: auto;

      img {
        width: 100%;
      }
    }
  }
}
